import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/solid'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import {
  ActionExecutionType,
  AutomationsMemberNotificationMatchOnType,
  AutomationsMemberNotificationMatchType,
  AutomationsMemberNotificationSendType,
  AutomationUserNotificationDataType,
} from '@stafflink/zod-types/src/automations'
import { Mention, MentionsInput } from 'react-mentions'
import {
  BaserowField,
  MembershipsByClient,
  MembershipsByClientVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import EditEmailMessageModal from 'src/components/Automations/AutomationActions/NotificationActionStepComponentEmailMessage/EditEmailMessageModal'
import NotificationActionStepComponentNotificationMessage from 'src/components/Automations/AutomationActions/NotificationActionStepComponentNotificationMessage'
import NotificationActionStepComponentRecipientItem from 'src/components/Automations/AutomationActions/NotificationActionStepComponentRecipientItem'
import {
  findDateTimeFieldsWithNoTimeZone,
  matchOnSelectOptions,
  matchTypeSelectOptions,
} from 'src/components/Automations/AutomationActions/utils'
import { GET_ACTIVE_MEMBERSHIPS } from 'src/components/Automations/queries'
import DebounceTextInput from 'src/components/DebounceTextInput/DebounceTextInput'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button'
import IconButton from 'src/components/Library/IconButton/IconButton'

import { SelectOption } from '../AutomationRequirements/AutocompleteComponents'

import NotificationActionStepComponentEmailMessage from './NotificationActionStepComponentEmailMessage/NotificationActionStepComponentEmailMessage'

interface NotificationActionStepComponentProps {
  actionStep: AutomationUserNotificationDataType
  onUpdate: (updatedReq: any, nestIndex?: number) => void
  nestIndex: number
  availableFields: BaserowField[]
  selectedActionType: ActionExecutionType
}

const NotificationActionStepComponent: React.FC<
  NotificationActionStepComponentProps
> = ({
  actionStep,
  onUpdate,
  nestIndex,
  availableFields,
  selectedActionType,
}) => {
  const [matchOn, setMatchOn] = useState<SelectOption>(null)
  const [baserowFieldOptions, setBaserowFieldOptions] = useState<
    SelectOption[]
  >([])
  const [selectedBaserowField, setSelectedBaserowField] =
    useState<SelectOption>(null)
  const [membershipOptions, setMembershipOptions] = useState<SelectOption[]>([])
  const [selectedMemberships, setSelectedMemberships] = useState<
    SelectOption[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)
  const [matchType, setMatchType] = useState<SelectOption>(null)
  const [openEditEmailModal, setOpenEditEmailModal] = useState<boolean>(false)
  const [dateDateTimeFieldsInMessage, setDateDateTimeFieldsInMessage] =
    useState<BaserowField[]>([])
  const [dateDateTimeFieldsInTitle, setDateDateTimeFieldsInTitle] = useState<
    BaserowField[]
  >([])

  const handleUpdateMatchOn = (selectedOption: SelectOption) => {
    // Update input state of selected option
    setMatchOn(selectedOption)

    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      matchingData: {
        ...actionStep.matchingData,
        matchOn: selectedOption.value,
        matchType:
          selectedOption.value ===
          AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS
            ? AutomationsMemberNotificationMatchType.MEMBERSHIP_ID
            : null,
        matchValues: [],
      },
    }
    setMatchType(
      selectedOption.value ===
        AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS
        ? matchTypeSelectOptions.find(
            (option) =>
              option.value ===
              AutomationsMemberNotificationMatchType.MEMBERSHIP_ID,
          )
        : null,
    )
    setSelectedMemberships([])
    setSelectedBaserowField(null)
    setMatchType(null)

    // Send up
    onUpdate(updatedActionItem)
  }

  const handleUpdateBaserowFieldSelection = (
    selectedOption: SelectOption,
    sendType: AutomationsMemberNotificationSendType,
  ) => {
    // Update input state of selected option
    setSelectedBaserowField(selectedOption)

    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      matchingData: {
        ...actionStep.matchingData,
        matchValues: [
          {
            label: selectedOption.label,
            value: selectedOption.value,
            sendType,
          },
        ],
        matchType: null,
      },
    }
    setMatchType(null)

    // Send up
    onUpdate(updatedActionItem)
  }

  const handleUpdateBaserowFieldTypeSelection = (
    selectedOption: SelectOption,
  ) => {
    // Update input state of selected option
    setMatchType(selectedOption)

    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      matchingData: {
        ...actionStep.matchingData,
        matchType: selectedOption.value,
      },
    }

    // Send up
    onUpdate(updatedActionItem)
  }

  const handleUpdateMessageText = (value: string) => {
    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      notificationMessage: value,
    }

    // Send up
    onUpdate(updatedActionItem)
  }

  const handleUpdateTitleText = (value: string) => {
    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      notificationTitle: value,
    }

    // Send up
    onUpdate(updatedActionItem)
  }

  const handleSetCustomFromName = (value: string) => {
    const updatedActionItem = {
      ...actionStep,
      customFromName: value,
    }
    // Send up
    onUpdate(updatedActionItem)
  }

  const handleUpdateMembershipSelection = (
    selectedOption: SelectOption[],
    sendType: AutomationsMemberNotificationSendType,
  ) => {
    // Update input state of selected option
    setSelectedMemberships(selectedOption)

    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      matchingData: {
        ...actionStep.matchingData,
        matchValues: selectedOption.map((option) => ({
          label: option.label,
          value: option.value,
          sendType,
        })),
      },
    }

    // Send up
    onUpdate(updatedActionItem)
  }

  const deleteActionItem = () => {
    onUpdate(null, nestIndex)
  }

  const updateActionData = async () => {
    setLoading(true)
    if (!actionStep) {
      setLoading(false)
      return
    }
    const matchOnValue = matchOnSelectOptions.find(
      (option) => option.value === actionStep?.matchingData?.matchOn,
    )

    const matchType = matchTypeSelectOptions.find(
      (option) => option.value === actionStep?.matchingData?.matchType,
    )

    const fieldOptions = availableFields.map((field) => ({
      value: field.id.toString(),
      label: field.name,
    }))

    setBaserowFieldOptions(fieldOptions)

    setMatchOn(matchOnValue)
    setMatchType(matchType)
    setLoading(false)
  }

  useEffect(() => {
    updateActionData()
  }, [availableFields, actionStep, membershipOptions])

  useEffect(() => {
    if (
      matchOn?.value === AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS
    ) {
      setSelectedMemberships(
        actionStep?.matchingData?.matchValues?.map((matchValue) => ({
          label: matchValue.label,
          value: matchValue.value,
          sendType: matchValue.sendType,
        })),
      )
    } else if (
      matchOn?.value ===
      AutomationsMemberNotificationMatchOnType.WORKFLOW_FIELD_VALUE
    ) {
      setSelectedBaserowField(
        baserowFieldOptions?.find(
          (option) =>
            option?.value.toString() ===
            actionStep?.matchingData?.matchValues[0]?.value.toString(),
        ),
      )
    }
  }, [matchOn, baserowFieldOptions])

  useEffect(() => {
    const getMembershipData = async () => {
      const memberships = await getMemberships({
        variables: { activeOnly: true },
      })
      setMembershipOptions(
        memberships?.data.membershipsByClient.map((membership) => ({
          value: membership.id.toString(),
          label: membership.user.name,
        })),
      )
    }
    getMembershipData()
  }, [])

  const [getMemberships] = useLazyQuery<
    MembershipsByClient,
    MembershipsByClientVariables
  >(GET_ACTIVE_MEMBERSHIPS, {
    onCompleted: (_data) => {},
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
      })
    },
  })

  useEffect(() => {
    findDateTimeFieldsWithNoTimeZone({
      fields: availableFields,
      title: actionStep?.notificationTitle,
      message: actionStep?.notificationMessage,
      setFieldsInTitle: setDateDateTimeFieldsInTitle,
      setFieldsInMessage: setDateDateTimeFieldsInMessage,
    })
  }, [actionStep, availableFields])

  return (
    <>
      {loading && !matchOn ? (
        <div className="flex w-full items-center justify-center gap-4 border-t border-gray-300 p-4 text-indigo-600">
          <CircularProgress color="inherit" className="!h-8 !w-8" />
          <p className="text-gray-400">Loading Action Data</p>
        </div>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          className={'flex justify-between border-t border-gray-300 p-4'}
        >
          <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-between gap-4">
              <p className="font-bold">Action {nestIndex + 1}</p>
              <Tooltip title={`Remove Action ${nestIndex + 1}`}>
                <div>
                  <Button
                    fullWidth={false}
                    variant="text"
                    className="min-w-[0] rounded-full p-2"
                    onClick={() => {
                      deleteActionItem()
                    }}
                  >
                    <XMarkIcon className="h-6 w-6 text-gray-500" />
                  </Button>
                </div>
              </Tooltip>
            </div>

            <NotificationActionStepComponentRecipientItem
              matchOn={matchOn}
              handleUpdateMatchOn={handleUpdateMatchOn}
              selectedBaserowField={selectedBaserowField}
              baserowFieldOptions={baserowFieldOptions}
              handleUpdateBaserowFieldSelection={
                handleUpdateBaserowFieldSelection
              }
              matchType={matchType}
              handleUpdateBaserowFieldTypeSelection={
                handleUpdateBaserowFieldTypeSelection
              }
              selectedMemberships={selectedMemberships}
              membershipOptions={membershipOptions}
              handleUpdateMembershipSelection={handleUpdateMembershipSelection}
            />

            {dateDateTimeFieldsInMessage.length > 0 && (
              <Tooltip
                title={
                  <>
                    <p>DateTime Fields Without Timezone:</p>
                    <ul>
                      {dateDateTimeFieldsInMessage.map((field) => (
                        <li key={field.id}>{field.name}</li>
                      ))}
                    </ul>
                    <p>
                      Your message will show time in GMT unless you set the
                      timezone of the field.
                    </p>
                  </>
                }
              >
                <div className={'py-2'}>
                  <div className="flex grow items-center gap-2 rounded border border-orange-300 bg-orange-50 p-2 text-sm text-orange-700">
                    <ExclamationTriangleIcon className="h-6 w-6" />
                    <p>
                      {`DateTime fields without timezone found in ${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification message' : 'email message'}`}
                    </p>
                  </div>
                </div>
              </Tooltip>
            )}
            {dateDateTimeFieldsInTitle.length > 0 && (
              <Tooltip
                title={
                  <>
                    <p>DateTime Fields Without Timezone:</p>
                    <ul>
                      {dateDateTimeFieldsInTitle.map((field) => (
                        <li key={field.id}>{field.name}</li>
                      ))}
                    </ul>
                    <p>
                      {`Your ${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification title' : 'email subject'} will show time in GMT unless you set the
                      timezone of the field.`}
                    </p>
                  </>
                }
              >
                <div className={'py-2'}>
                  <div className="flex grow items-center gap-2 rounded border border-orange-300 bg-orange-50 p-2 text-sm text-orange-700">
                    <ExclamationTriangleIcon className="h-6 w-6" />
                    <p>
                      {`DateTime fields without timezone found in ${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification title' : 'email subject'}`}
                    </p>
                  </div>
                </div>
              </Tooltip>
            )}

            {selectedActionType === ActionExecutionType.SEND_EMAIL && (
              <FormInputRow label={'Custom Sender Name (Optional)'}>
                <FormControl className="w-full">
                  <DebounceTextInput
                    defaultValue={actionStep?.customFromName}
                    onChange={(value) => {
                      handleSetCustomFromName(value)
                    }}
                    name={'CustomSenderName'}
                    setKey={'CustomSenderName'}
                    label={'Custom Sender Name (Optional)'}
                    className={'w-full bg-white'}
                  />
                </FormControl>
              </FormInputRow>
            )}

            <FormInputRow
              label={`${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'Notification Title' : 'Email Subject'}`}
            >
              <FormControl
                className="w-full"
                error={!actionStep?.notificationTitle}
              >
                <MentionsInput
                  value={actionStep?.notificationTitle}
                  onChange={(event) => {
                    handleUpdateTitleText(event.target.value)
                  }}
                  forceSuggestionsAboveCursor
                  allowSpaceInQuery
                  placeholder={`${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'Notification Title' : 'Email Subject'} -> Use @ To Insert A Field Value.`}
                  className="w-full rounded border-2 border-gray-300 bg-white p-2"
                  style={{
                    input: {
                      paddingLeft: '15px',
                      paddingTop: '10px',
                      zIndex: 9999, // Ensures the suggestions list appears on top
                    },
                    suggestions: {
                      list: {
                        borderRadius: '5px',
                        border: '1px solid #9ca3af',
                        padding: '5px',
                        zIndex: 9999, // Ensures the suggestions list appears on top
                        maxHeight: '150px', // Set the maximum height for the list
                        overflowY: 'auto', // Enable scrolling when content exceeds max height
                      },
                      item: {
                        padding: '5px',
                        borderBottom: '1px solid #f3f4f6',
                        '&focused': {
                          backgroundColor: '#f3f4f6',
                        },
                        fontSize: '12px',
                        zIndex: 9999, // Ensures the suggestions list appears on top
                      },
                    },
                  }}
                >
                  <Mention
                    trigger="@"
                    data={availableFields.map((field) => ({
                      id: field.id,
                      display: field.name,
                    }))}
                    displayTransform={(_, display) => `@${display}`}
                  />
                </MentionsInput>

                <FormHelperText>
                  {!actionStep?.notificationTitle &&
                    `Enter a ${selectedActionType === ActionExecutionType.SEND_NOTIFICATION ? 'notification title' : 'email subject'}`}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
            {selectedActionType === ActionExecutionType.SEND_NOTIFICATION && (
              <FormInputRow label="Notification Message">
                <FormControl
                  className="w-full"
                  error={!actionStep?.notificationMessage}
                >
                  <NotificationActionStepComponentNotificationMessage
                    actionStep={actionStep}
                    handleUpdateMessageText={handleUpdateMessageText}
                    availableFields={availableFields}
                  />
                </FormControl>
              </FormInputRow>
            )}
            {selectedActionType === ActionExecutionType.SEND_EMAIL && (
              <FormInputRow label="Email Message">
                <FormControl
                  className="w-full"
                  error={!actionStep?.notificationMessage}
                >
                  <Stack
                    direction={'row'}
                    spacing={2}
                    className={'flex'}
                    alignItems={'center'}
                  >
                    <NotificationActionStepComponentEmailMessage
                      actionStep={actionStep}
                      handleUpdateMessageText={handleUpdateMessageText}
                      availableFields={availableFields}
                      editable={false}
                      showMenuBar={false}
                      className={'min-h-[200px]'}
                    />
                    <Tooltip arrow placement="top" title={'Edit Message'}>
                      <div>
                        <IconButton
                          onClick={() => {
                            setOpenEditEmailModal(true)
                          }}
                        >
                          <PencilIcon className="h-4 w-4 text-blue-500" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Stack>
                </FormControl>
              </FormInputRow>
            )}
          </div>
        </Stack>
      )}
      {openEditEmailModal && (
        <EditEmailMessageModal
          openModal={openEditEmailModal}
          handleClose={() => {
            setOpenEditEmailModal(false)
          }}
          actionStep={actionStep}
          handleUpdateMessageText={handleUpdateMessageText}
          availableFields={availableFields}
        />
      )}
    </>
  )
}

export default NotificationActionStepComponent
