import { Dispatch, SetStateAction } from 'react'

import {
  AutomationsMemberNotificationMatchOnType,
  AutomationsMemberNotificationMatchType,
} from '@stafflink/zod-types/src/automations'
import { BaserowField } from 'types/graphql'

import { SelectOption } from 'src/components/Automations/AutomationRequirements/AutocompleteComponents'

interface FindDateTimeFieldsWithNoTimeZone {
  fields: BaserowField[]
  title: string
  message: string
  setFieldsInTitle: Dispatch<SetStateAction<BaserowField[]>>
  setFieldsInMessage: Dispatch<SetStateAction<BaserowField[]>>
}

export const findDateTimeFieldsWithNoTimeZone = ({
  fields,
  title,
  message,
  setFieldsInTitle,
  setFieldsInMessage,
}: FindDateTimeFieldsWithNoTimeZone) => {
  const isDateField = (fieldSchema: Record<string, any>) => {
    switch (fieldSchema.type) {
      case 'date':
      case 'last_modified':
      case 'created_on':
        return true
      case 'formula':
        return fieldSchema.formula_type === 'date'
      case 'lookup':
        return fieldSchema.array_formula_type === 'date'
      default:
        return false
    }
  }

  // First find all the date fields
  const availableDateFields = fields.filter((field) => isDateField(field))

  // Now find the ones with time but no timezone
  const availableTimeFields = availableDateFields.filter((field) => {
    return (
      field.date_include_time === true && field.date_force_timezone === null
    )
  })

  // Function to check if field name is in the string in either format
  const isFieldInText = (text: string, field: BaserowField) => {
    return (
      text?.includes(`@${field.name}`) ||
      text?.includes(`@[${field.name}](${field.id})`)
    )
  }

  // Now find out if the field Ids exist in the message or title
  const fieldsInTitle = availableTimeFields.filter((field) => {
    return isFieldInText(title, field)
  })
  setFieldsInTitle(fieldsInTitle)

  const fieldsInMessage = availableTimeFields.filter((field) => {
    return isFieldInText(message, field)
  })
  setFieldsInMessage(fieldsInMessage)
}

export const matchOnSelectOptions: SelectOption[] = [
  {
    value: AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS,
    label: 'Specific Users',
  },
  {
    value: AutomationsMemberNotificationMatchOnType.WORKFLOW_FIELD_VALUE,
    label: 'Workflow Field Value',
  },
]

export const matchTypeSelectOptions: SelectOption[] = [
  {
    value: AutomationsMemberNotificationMatchType.EMAIL,
    label: 'Email Address',
  },
  {
    value: AutomationsMemberNotificationMatchType.FULL_NAME,
    label: 'Full Name',
  },
  {
    value: AutomationsMemberNotificationMatchType.MEMBERSHIP_ID,
    label: 'Membership ID',
  },
  {
    value: AutomationsMemberNotificationMatchType.BASEROW_COLLABORATOR,
    label: 'Membership',
  },
]
