import React, { FC } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import {
  AutomationsMemberNotificationMatchOnType,
  AutomationsMemberNotificationSendType,
} from '@stafflink/zod-types/src/automations'

import { SelectOption } from 'src/components/Automations/AutomationRequirements/AutocompleteComponents'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import { matchOnSelectOptions, matchTypeSelectOptions } from './utils'

interface NotificationActionStepComponentRecipientItemProps {
  matchOn: SelectOption
  handleUpdateMatchOn: (newValue: SelectOption) => void
  selectedBaserowField: SelectOption
  baserowFieldOptions: SelectOption[]
  handleUpdateBaserowFieldSelection: (
    newValue: SelectOption,
    sendType: AutomationsMemberNotificationSendType,
  ) => void
  matchType: SelectOption
  handleUpdateBaserowFieldTypeSelection: (newValue: SelectOption) => void
  selectedMemberships: SelectOption[]
  membershipOptions: SelectOption[]
  handleUpdateMembershipSelection: (
    newValue: SelectOption[],
    sendType: AutomationsMemberNotificationSendType,
  ) => void
}

const NotificationActionStepComponentRecipientItem: FC<
  NotificationActionStepComponentRecipientItemProps
> = ({
  matchOn,
  handleUpdateMatchOn,
  selectedBaserowField,
  baserowFieldOptions,
  handleUpdateBaserowFieldSelection,
  matchType,
  handleUpdateBaserowFieldTypeSelection,
  selectedMemberships,
  membershipOptions,
  handleUpdateMembershipSelection,
}) => {
  return (
    <>
      <FormInputRow label="Source">
        <FormControl className="w-full" error={!matchOn}>
          <Autocomplete
            value={matchOn}
            options={matchOnSelectOptions}
            onChange={(_event: any, newValue: any) => {
              handleUpdateMatchOn(newValue)
            }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => {
              return option?.value === value?.value
            }}
            id="select-field-name"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  matchOnSelectOptions?.length > 0 && 'Select a Field'
                }
                className="bg-white"
                error={!matchOn}
              />
            )}
            fullWidth
            size="small"
            disableClearable
          />

          <FormHelperText>
            {!matchOn && 'Select a User Data Source'}
          </FormHelperText>
        </FormControl>
      </FormInputRow>

      {matchOn?.value ===
        AutomationsMemberNotificationMatchOnType.WORKFLOW_FIELD_VALUE && (
        <>
          <Tooltip
            title={
              'Please make sure that any field data relates to users in your hub only. You can not send emails to addresses not associated with hubs users.'
            }
          >
            <div className="flex items-center gap-2 rounded border border-orange-300 bg-orange-50 p-2 text-sm text-orange-700">
              <ExclamationTriangleIcon className="h-6 w-6" />
              <p>Automation Emails can only be sent to Hub user emails.</p>
            </div>
          </Tooltip>
          <FormInputRow label="Field Name">
            <FormControl className="w-full" error={!selectedBaserowField}>
              <Autocomplete
                value={selectedBaserowField}
                options={baserowFieldOptions}
                onChange={(_event: any, newValue: any) => {
                  handleUpdateBaserowFieldSelection(
                    newValue,
                    AutomationsMemberNotificationSendType.RECIPIENT,
                  )
                }}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value?.value
                }}
                id="select-field-name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      baserowFieldOptions?.length > 0 && 'Select a Field'
                    }
                    className="bg-white"
                    error={!selectedBaserowField}
                  />
                )}
                fullWidth
                size="small"
                disableClearable
              />

              <FormHelperText>
                {!selectedBaserowField && 'Select a Field Value'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>
        </>
      )}

      {matchOn?.value ===
        AutomationsMemberNotificationMatchOnType.WORKFLOW_FIELD_VALUE &&
        selectedBaserowField && (
          <FormInputRow label="Data Type">
            <FormControl className="w-full" error={!matchType}>
              <Autocomplete
                value={matchType}
                options={matchTypeSelectOptions}
                onChange={(_event: any, newValue: any) => {
                  handleUpdateBaserowFieldTypeSelection(newValue)
                }}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value?.value
                }}
                id="select-field-name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      baserowFieldOptions?.length > 0 && 'Select a Source Type'
                    }
                    className="bg-white"
                    error={!matchType}
                  />
                )}
                fullWidth
                size="small"
                disableClearable
              />

              <FormHelperText>
                {!matchType && 'Select a Source Type'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>
        )}

      {matchOn?.value ===
        AutomationsMemberNotificationMatchOnType.MEMBERSHIP_IDS && (
        <FormInputRow label="Users">
          <FormControl
            className="w-full"
            error={selectedMemberships?.length === 0}
          >
            <Autocomplete
              multiple
              value={selectedMemberships}
              options={membershipOptions}
              onChange={(_event: any, newValue: any) => {
                handleUpdateMembershipSelection(
                  newValue,
                  AutomationsMemberNotificationSendType.RECIPIENT,
                )
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => {
                return option?.value === value?.value
              }}
              id="select-field-name"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    selectedMemberships?.length === 0 && 'Select a User'
                  }
                  className="bg-white"
                  error={selectedMemberships?.length === 0}
                />
              )}
              fullWidth
              size="small"
              disableClearable
            />

            <FormHelperText>
              {selectedMemberships?.length === 0 && 'Select a User'}
            </FormHelperText>
          </FormControl>
        </FormInputRow>
      )}
    </>
  )
}

export default NotificationActionStepComponentRecipientItem
